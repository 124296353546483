<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- Export Modal -->
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
          type="button"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>

    <div class="manage-accounting my-3" style="padding: 0.4em">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <div
          class="menu d-flex flex-wrap align-items-center justify-content-between my-3"
        >
          <button
            v-if="!isNativeApp"
            class="btn-outline primary-btn me-3 d-flex my-1"
            @click="$bvModal.show('export-modal')"
          >
            <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
            <p>{{ $t("depreciation.export_file") }}</p>
          </button>
        </div>
        <ConstructionTable
          :page="currentPage"
          :perPage="perPage"
          :assetData="itemData"
        ></ConstructionTable>
        <!-- <div class="d-flex justify-content-center mt-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
            @change="loadAssetData"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
const DEFAULT_CONSTRUCTION = {
  documentNo: "",
  name: "",
  selectExist: false,
  budget: 0,
  invoiceNo: "",
  invoiceDate: new Date(),
  vendor: "",
  list: "",
  excludeVat: 0,
  vat: 0,
  vatType: "percent",
};
const DEFAULT_EDIT = {
  constructionId: null,
  documentNo: "",
  name: "",
  selectExist: true,
  budget: 0,
  invoiceNo: "",
  invoiceDate: new Date(),
  vendor: "",
  list: "",
  excludeVat: 0,
  vat: 0,
  vatType: "value",
};

const DEFAULT_CONVERT = {
  fixedAssetNo: "",
  selectExist: false,
  assetName: "",
  purchaseDate: new Date(),
  startDate: new Date(),
  purchasePrice: 0,
};

import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import ConstructionTable from "../../components/Table/Depreciation/ConstructionTable.vue";
import axios from "axios";
import { authHeader } from "../../store/actions";
import Loading from "vue-loading-overlay";
import moment from "moment";
import baseUrl from "../../util/backend";
export default {
  name: "ConstructingHistory",
  components: {
    ConstructionTable,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      company_id: null,
      selectedAsset: [],
      filterOptions: {},
      currentPage: 1,
      totalRows: 1,
      perPage: 100,
      itemData: [],
    };
  },

  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      companyName: "companyName",
    }),
    selectedAssetLength() {
      return this.selectedAsset.length > 0;
    },
  },

  methods: {
    async onExport(isMail) {
      this.isLoading = true;
      try {
        const filename = `${this.$t("depreciation.convert_history")}_${
          this.companyName
        }_${moment().format("DD-MM-YYYY_HH-mm")}.xlsx`;
        const res = await axios.get(
          `${baseUrl()}asset/getduringconstructionlog/${
            this.company_id
          }/download`,
          {
            ...authHeader(),
            responseType: "blob",
          }
        );
        if (!isMail) {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          const elink = document.createElement("a");
          elink.download = filename;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    async loadAssetData() {
      this.selectedAsset = [];
      this.isLoading = true;
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(
          `${baseUrl()}asset/getlogduringconstruction/${this.company_id}`,
          authHeader()
        );
        this.totalRows = res.data.data.count;
        this.itemData = res.data.data;
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.loadAssetData();
  },
};
</script>

<style lang="scss" scoped>
input,
select {
  height: 45px;
}

.manage-accounting {
  text-align: left;
}

.company-name {
  font-size: 18px;
}

.btn-icon {
  color: #007afe;
  padding: 10px 10px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  border-color: #007afe;
}

.btn-outline {
  p {
    margin: 0;
  }
  min-width: fit-content;
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }
}

.search-box {
  input {
    width: 200px;
    background-color: transparent !important;
    border-radius: 10px;
  }
  img {
    margin: 0 10px;
  }
  background-color: #e0e0e0 !important;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}
</style>
