<template>
  <div class="d-flex align-items-center h-100">
    <img
      width="20"
      height="20"
      style="cursor: pointer;"
      src="../../../../assets/Depreciation/edit-pencil-blue.svg"
      alt=""
      @click="editConstruction"
    />
    <div
      style="border-right:3px solid #c4c4c4; height:60%; margin: 0 10px"
    ></div>
    <img
      width="20"
      height="20"
      style="cursor: pointer;"
      src="../../../../assets/Depreciation/delete-bin.svg"
      alt=""
      @click="deleteConstruction"
    />
  </div>
</template>

<script>
export default {
  methods: {
    editConstruction() {
      this.params.editValue(this.params.value);
    },
    deleteConstruction() {
      this.params.deleteValue(this.params.value);
    },
  },
};
</script>
